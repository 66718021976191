import { makeAutoObservable } from "mobx";

export default class UserStore {
   constructor() {
    this._isAuth = false;
    this._countHeart = 10;
    this._countcoins = 0;
    this._id = 0;
    this._timeFarming = 0;
    this._isStartFarming = false;
    this._isTakeCoins = false;
    this._isGame = false;
    this._photo = '';
    this._claimcoins = '';
    this._daysCount = 0;
    makeAutoObservable(this);
   }
   setIsAuth(bool) {
    this._isAuth = bool;
   }

   setPhoto(string) {
      this._photo = string;
   }

   setHeart(int) {
    this._countHeart = int;
   }

   setCoins(int) {
    this._countcoins = int;
   }

   setId(int) {
    this._id = int;
   }

   setTimeFarming(int) {
    this._timeFarming = int;
   }

   setIsStartFarming(bool) {
    this._isStartFarming = bool;
   }

   setIsTakeCoins(bool) {
    this._isTakeCoins = bool;
   }

   setIsGame(bool) {
      this._isGame = bool;
   }

   setClaimcoins(int) {
      this._claimcoins = int;
   }

   setDaysCount(int) {
      this._daysCount = int;
   }

   get id() {
    return this._id;
   }

   get daysCount() {
      return this._daysCount;
   }
   
   get isAuth() {
      return this._isAuth;
   }

   get heart() {
      return this._countHeart;
   }

   get claimcoins() {
      return this._claimcoins;
   }

   get coins() {
      return this._countcoins;
   }

   get timeFarming() {
      return this._timeFarming;
   }

   get photo() {
      return this._photo;
   }

   get isTimeFarming() {
      return this._isStartFarming
   }

   get isTakeCoins() {
      return this._isTakeCoins;
   }
   get isGame() {
      return this._isGame;
   }
   get 
} 
