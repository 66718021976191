import React, { useState, useEffect } from "react";

const CachedImages = ({isLoading, setIsLoading, setShowApp}) => {
 
  const [progress, setProgress] = useState(0);

  const imagesToCache = [
    "/assets/sprites/test.png",
    "/assets/images/image1.jpg",
    "/assets/images/image2.jpg",
    // Добавьте остальные пути к изображениям
  ];

  const preloadImages = (imageArray) => {
    return Promise.all(
      imageArray.map((src, index) => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.src = src;
          img.onload = () => {
            setProgress((prev) => prev + (100 / imageArray.length));
            resolve(src);
          };
          img.onerror = reject;
        });
      })
    );
  };

  useEffect(() => {
    preloadImages(imagesToCache)
      .then(() => {
        setShowApp(true);
        setIsLoading(false); // Все изображения загружены
      })
      .catch((error) => {
        console.error("Ошибка загрузки изображения:", error);
        setIsLoading(false); // При ошибке завершить загрузку
      });
  }, []);

  return (
   <></>
  );
};

export default CachedImages;
