import react, { useContext } from 'react'
import cls from './daylyHearts.module.css'
import { Context } from '../../../..';
import { setClaimDays } from '../../../../http/userAPI';

const DaylyHearts = ({days, setDays, userSet}) => {

    const {user} = useContext(Context);

    async function parseHearts() {
        const newHearts =  days > 3 ? 3 : days;
        user.setHeart(user.heart + newHearts)
        setDays(0);
        userSet = 0;
        await setClaimDays(user.id, newHearts);
    }
    return (
        <div className={cls.main}>
            <p>{days} DAYS CHECK-IN</p>
            <p>TAKE YOUR REWARD!</p>
            <div className={cls.hearts}>
                <img src='./assets/images/Heart2.png' alt='heart'></img>
                <img style={{opacity: `${days > 1 ? 1 : 0.3}`}} src='./assets/images/Heart2.png' alt='heart'></img>
                <img style={{opacity: `${days > 2 ? 1 : 0.3}`}} src='./assets/images/Heart2.png' alt='heart'></img>
            </div>
            <button onClick={() => parseHearts()} className={cls.button}>GOT IT</button>
        </div>
    )
}

export default DaylyHearts;