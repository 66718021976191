import {FC, useContext, useEffect, useRef, useState} from "react";

import Game from "../../../compotents/GameCanvas/GameCanvas";
import styles from "./styles.module.css";
import { Link } from "react-router-dom";
import GameState from "../../../game/state/gameManager";
import { Context } from "../../../index";
import { AliveScope } from "react-activation";
import { observer } from "mobx-react-lite";
import { clearClaim, setCoinsUser } from "../../../http/userAPI";
import PlanetIcon from "./Icon/Icon";
import { formatedCoins } from "../../helpers/coinFormated";
import {getLeague} from '../../helpers/getLeague'
const claimReward = 25000;

const HomeCont = (({setGameState, postTime}) => {

    const {user} = useContext(Context);
    
    const [time, setTime] = useState(postTime);
    const [timeParse, setTimeParse] = useState('0:0:0');
    const [isParse, setIsParse] = useState(true);
    const [isTakeCoins, setIsTakeCoins] = useState(true);

    const [isPressed, setIsPressed] = useState(false);
    const [isAnimationCompleted, setIsAnimationCompleted] = useState(false);
    const [opac, setOpac] = useState(0); 
    const [coinsShow, SetCoinsShow] = useState(0);
   

    const FULL_TIME = 10 * 3600;

    function parseTime(time) {
        const remainTime = FULL_TIME - time;
        setTimeParse(`${Math.floor(remainTime/3600)}:${Math.floor((remainTime % 3600) / 60)}:${Math.floor(remainTime%60)}`);
    }

    function setCoins() {
    
        for(let i = 0; i < claimReward; i+=100) {
            setTimeout(() => {
                SetCoinsShow(p => p + 100);
            
            }, i/ 20 * 1)
        }
    }

    function getProccent() {
        const persent = time/(FULL_TIME/100);
        if(persent > 100) 
        {
            return 100;
        }

        return Math.floor(time / (FULL_TIME/100));
    }

    function startGame() {
        if(user.heart < 1) return;
        user.setHeart(user.heart - 1)
        setGameState(0);
    }

    

    function updateTime() {
        if(getProccent() === 100) {
      
            setIsParse(false);
            setIsPressed(false);
            setIsTakeCoins(false);
            setIsAnimationCompleted(false);
            setOpac(1);
            return;
        }

        parseTime(time);
        
    }
    
    function updateButton() {
        setIsParse(true);
        setIsTakeCoins(true);
        setTime(0);
    }

    const [isStars, setIsStars] = useState(false);

    async function takeCoins() {

        setIsStars(true);
        setTimeout( async () => {
            await clearClaim(user.id);
            setIsStars(false);
            setIsTakeCoins(true);
            setCoins();
            user.setTimeFarming(0);
        }, 1500)
        
    }



    const stars =[];
    const starsDown = []

    for(let i=0; i<=10; i++) {
        const nameCl = [styles.coin, styles.up];
        stars.push((<img className={nameCl.join(' ')} src="./assets/images/Coin2.png"/>));
    }
    for(let i=0; i<=10; i++) {
        const nameCl = [styles.coin, styles.down];
        starsDown.push((<img className={nameCl.join(' ')} src="./assets/images/Coin2.png"/>));
    }

    const canvasRef = useRef(null)
    const gameRef = useRef();

    const onCanvasResize = () => {
        if (!canvasRef.current) return;

        canvasRef.current.width = window.innerWidth;
        canvasRef.current.height = window.innerHeight;
        canvasRef.current.style.width = `${window.innerWidth}px`;
        canvasRef.current.style.height = `${window.innerHeight}px`;

        canvasRef.current.getContext('2d').transform(1, 0, 0, 1, 0, 0);
    }

    useEffect(() => {
        if (!canvasRef.current) return;

        onCanvasResize();

        window.addEventListener('resize', onCanvasResize);

        gameRef.current = new Game(canvasRef.current);
        
        gameRef.current.start();

        return () => {
            window.removeEventListener('resize', onCanvasResize);

            gameRef.current.teardown();

            gameRef.current = null;
        }
    }, []);

    useEffect(() => {
        console.log(postTime)
        const intervalId = setInterval(() => {
          setTime((prevTime) => prevTime + 1);
          
        }, 1000); 
        SetCoinsShow(user.coins);
    
        return () => clearInterval(intervalId); 
      }, []);

      useEffect(() => {
        if(time <= 10 * 60 * 60) {
            console.log('woo');
            updateTime();
        }
        
      }, [time]);

const animationTimeout = useRef(null);
const vibrationInterval = useRef(null);

const handleMouseUp = () => { 
  setIsPressed(false);
  if (!isAnimationCompleted) {
    if (animationTimeout.current) {
      clearTimeout(animationTimeout.current);
      animationTimeout.current = null;
    }
    if (vibrationInterval.current) {
      clearInterval(vibrationInterval.current);
      vibrationInterval.current = null;
    }
  }
};

const handleMouseDown = async (e) => {
  e.preventDefault();
  setIsPressed(true);
  setIsAnimationCompleted(false);

  const vibrationDuration = 1500;
  const vibrationIntervalMs = 200;
  let count = 0;

  const startVibration = () => {
    count++;
    try {
        if (count > 5) {
      window.Telegram.WebApp.HapticFeedback.impactOccurred('heavy');
    } else {
      window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
    }

    }
    catch(e) {
        console.log(e);
    }
    
  };

  vibrationInterval.current = setInterval(startVibration, vibrationIntervalMs);

  animationTimeout.current = setTimeout( async () => {
    if (vibrationInterval.current) {
      clearInterval(vibrationInterval.current);
      vibrationInterval.current = null;
    }

    // Выполняем действия после окончания вибрации
    takeCoins();
    //await setCoinsUser(user.id, claimReward);
    
    user.setCoins(user.coins + claimReward);
    
    setTimeout(() => {
      setOpac(0);
    }, 500);
    setTimeout(() => {
      setIsAnimationCompleted(true);
    }, 3000);
  }, vibrationDuration);
};

    
      const [username, setUsername] = useState(window?.Telegram?.WebApp?.initDataUnsafe?.user?.username); // Добавляем состояние для username
    
      
      function getAnimation(){
        const predefinedClass = 'coin';
        const classes = ['fir', 'sec', 'thir', 'fiv'];
        const randomClass = classes[Math.floor(Math.random() * classes.length)];

        return {
          class: `${predefinedClass} ${randomClass}`,
        };
      }

    return (
        <>
            <div className={styles.body}>
                <div className={styles.content}>
                <Link to={'/leaders'} className={styles.name}>
                    <PlanetIcon coins={user.coins}></PlanetIcon>
                    <span>{username ? username : 'John'}</span>
                </Link>
                <div className={styles.coins}>
                    <img src="./assets/images/Coin2.png" alt=""></img>
                    {formatedCoins(coinsShow)}</div>
                </div>
                <div className={styles.wallet}>
                    <img src="./assets/sprites/wallet.png"></img>
                    <span>Connect wallet </span>
                </div>
            </div>
            <div className={styles.downCont}>
            {
                !isTakeCoins ?
                 <div
                    style={{opacity:opac}}
                    // onClick={() => takeCoins()} 
                    onMouseDown={handleMouseDown}
                    onMouseUp={handleMouseUp}
                    onTouchStart={handleMouseDown}
                    onTouchEnd={handleMouseUp}
                    className={`${styles.doneButton} ${isPressed ? styles.pressed : ''} ${isAnimationCompleted ? styles.completed : ''}`}>
                    <AliveScope>
                        <div className={styles.doneCont}>
                        {
                            isStars ? 
                                (
                                    <div className={styles.coinsCont}>
                                        {
                                        <div>{stars.map((el, index) => (
                                            <div key={index} className={getAnimation().class}>
                                                {el}
                                            </div>                                
                                        ))}</div>
                                        }
                                        {
                                        <div>{starsDown.map((el, index) => (
                                            <div key={index} className={getAnimation().class}>
                                                {el}
                                            </div>                                
                                        ))}</div>
                                        }
                                        {
                                        <div>{stars.map((el, index) => (
                                            <div key={index} className={getAnimation().class}>
                                                {el}
                                            </div>                                
                                        ))}</div>
                                        }
                                    </div>
                                )
                            : <></>
                        }
                        
                        <span className={styles.span}>CLAIM
                        <img src="./assets/images/Coin2.png" alt="coin" className={styles.claimCoin}></img>
                         25 000
                        <p className={styles.spanText}>press and hold</p>
                        </span>
                    </div>
                    </AliveScope>
                    
                </div> :
                
                    isParse ? 
                    <div className={styles.buttonParse} style={{opacity:`${1-opac}`}}>
                        <div className={styles.buttonCont}>
                            <div className={styles.progressbar} style={{width:`${getProccent()}%`}}></div>
                            <span className={styles.timer}>{timeParse}</span>
                        </div>
                    </div>
                    :  <div
                        onClick={() => updateButton()}
                        className={styles.button}>start farming
                    </div>
            }
            </div>
            
            <div className={styles.playButton} onClick={() => startGame()}>
                <img className={styles.ballImg} src="../../assets/sprites/Ball.png"></img>
                <div className={styles.textBall}>
                    <span className={styles.playText}>PLAY</span>
                    <span><img src="../assets/sprites/Heart2.png"></img>{user.heart}</span>
                </div>
            </div>

  
        </>
        
    );
})

export default HomeCont
