import { FC, useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination } from 'swiper/modules';

import './slider.css';
import styles from "./styles.module.css";

import Pluto from '../../../../assets/images/planets/pluto.png';
import Neptune from '../../../../assets/images/planets/neptun.png';
import Uranus from "../../../../assets/images/planets/uran.png";
import Saturn from "../../../../assets/images/planets/saturn.png";
import Jupiter from "../../../../assets/images/planets/jupiter.png";
import Mars from "../../../../assets/images/planets/mars.png";
import Earth from '../../../../assets/images/planets/earth.png';
import Venera from '../../../../assets/images/planets/venera.png';
import Merkuri from '../../../../assets/images/planets/merkuri.png';
import Sun from '../../../../assets/images/planets/sun.png';

import LeguageBanner from "../LegueBanner/LegueBanner";

export const LeagueSwiper = ({ setCurrentLeguage }) => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const handleSlideChange = (swiper) => {
        setCurrentSlide(swiper.activeIndex);
        setCurrentLeguage(swiper.activeIndex + 1);
    };

    return (
        <div className={styles.container}>
            <div className={styles.carouselWrapper}>
                <Swiper
                    modules={[Navigation, Pagination]}
                    navigation
                    speed={1000}
                    onSlideChange={handleSlideChange}
                    className={styles.swiperContainer}
                >
                    <SwiperSlide className={`${styles.caruselItem} ${styles.pluto}`}>
                        <img src={Pluto} alt="PlutoImage" />
                        <div className={styles.circle}></div>
                        <LeguageBanner minCount={0} maxCount={30000} text={"Pluto"} color={'#CDC8C8'} />
                    </SwiperSlide>
                    <SwiperSlide className={`${styles.caruselItem} ${styles.neptun}`}>
                        <img src={Neptune} alt="NeptuneImage" />
                        <div className={styles.circle}></div>
                        <LeguageBanner minCount={30000} maxCount={100000} text={"Neptune"} color={'#72CBF6'} />
                    </SwiperSlide>
                    <SwiperSlide className={`${styles.caruselItem} ${styles.uran}`}>
                        <img src={Uranus} alt="UranusImage" />
                        <div className={styles.circle}></div>
                        <LeguageBanner minCount={100000} maxCount={250000} text={"Uranus"} color={'#21B5CA'} />
                    </SwiperSlide>
                    <SwiperSlide className={`${styles.caruselItem} ${styles.saturn}`}>
                        <img src={Saturn} alt="SaturnImage" />
                        <div className={styles.circle}></div>
                        <LeguageBanner minCount={250000} maxCount={500000} text={"Saturn"} color={'#D09A75'} />
                    </SwiperSlide>
                    <SwiperSlide className={`${styles.caruselItem} ${styles.jupiter}`}>
                        <img src={Jupiter} alt="JupiterImage" />
                        <div className={styles.circle}></div>
                        <LeguageBanner minCount={500000} maxCount={1000000} text={"Jupiter"} color={'#E8A136'} />
                    </SwiperSlide>
                    <SwiperSlide className={`${styles.caruselItem} ${styles.mars}`}>
                        <img src={Mars} alt="MarsImage" />
                        <div className={styles.circle}></div>
                        <LeguageBanner minCount={1000000} maxCount={2000000} text={"Mars"} color={'#E06937'} />
                    </SwiperSlide>
                    <SwiperSlide className={`${styles.caruselItem} ${styles.earth}`}>
                        <img src={Earth} alt="EarthImage" />
                        <div className={styles.circle}></div>
                        <LeguageBanner minCount={2000000} maxCount={5000000} text={"Earth"} color={'#349FF8'} />
                    </SwiperSlide>
                    <SwiperSlide className={`${styles.caruselItem} ${styles.venera}`}>
                        <img src={Venera} alt="VeneraImage" />
                        <div className={styles.circle}></div>
                        <LeguageBanner minCount={5000000} maxCount={10000000} text={"Venus"} color={'#F69F37'} />
                    </SwiperSlide>
                    <SwiperSlide className={`${styles.caruselItem} ${styles.mercuri}`}>
                        <img src={Merkuri} alt="MerkuriImage" />
                        <div className={styles.circle}></div>
                        <LeguageBanner minCount={10000000} maxCount={20000000} text={"Mercury"} color={'#F18727'} />
                    </SwiperSlide>
                    <SwiperSlide className={`${styles.caruselItem} ${styles.sun}`}>
                        <img src={Sun} alt="SunImage" />
                        <div className={styles.circle}></div>
                        <LeguageBanner minCount={20000000} maxCount={100000000} text={"Sun"} color={'#FBB502'} />
                    </SwiperSlide>
                </Swiper>
            </div>
        </div>
    );
};

export default LeagueSwiper;
