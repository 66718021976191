import { FC, useContext, useEffect, useState } from "react";
import style from './style.module.css';
import GameState from "../../../game/state/gameManager";
import { Context } from "../../../index";
import { Link } from "react-router-dom";
import Cube from "../Dice/Dice";
import { setCoinsUser } from "../../../http/userAPI";
import './style.css'
import { formatedCoins } from "../../helpers/coinFormated";

const GameOver = ({ setGameState, visible, setState }) => {
    const rootCls = [style.main];

    const { user } = useContext(Context);
    const [isRoll, setIsRoll] = useState(GameState.isRoll);
    const [isPrise, setIsPrise] = useState(false);
    const [coinsShowing, setCoinsShowing] = useState(GameState.coins);
    const [isAnim, setIsAnim] = useState(false);
    const [coinsCount, setCoinsCount] = useState(0);

    if (visible) {
        rootCls.push(style.active);
    }

    useEffect(() => {
        setIsRoll(GameState.isRoll);
        //setCoinsShowing(GameState.coins);
    }, [GameState.isRoll]);

    useEffect(() => {
        if(GameState.state !== 0) {
            setCoinsShowing(GameState.coins)
        }
    }, [GameState.coins])

    useEffect(() => {
        console.log(GameState.isRoll)
        if(GameState.isRoll) {
            setIsRoll(true);
        }
    }, [GameState.isRoll])

    const resetGame = () => {
        setCoins();
        if (user.heart < 1) return;
        GameState.setRestGame(true);
        GameState.coins = 0;
        setGameState(0);
        user.setHeart(user.heart - 1);
    };

    const setCoins = async () => {
        user.setCoins(coinsShowing + Number(user.coins));
       await setCoinsUser(user.id, coinsShowing);
    };


    const link = 'https://t.me/tobobobobo_bot';

    const sendLink = () => {
        const url = `https://t.me/share/url?url=${encodeURIComponent(`i take coins, try play ${link}`)}`;
        window.open(url, '_blank');
    };

    const arrCoins = Array(coinsCount).fill(0);

    function getAnimation(index, size){
        const predefinedClass = 'coinImage';
        const classes = ['firS', 'secS', 'thirS', 'fivS'];
        let randomClass;
        
        console.log(classes[0]);
        if(index < size / 3) {
            randomClass = classes[0]
        } else

        if(index < size / 3 * 2) {
            randomClass = classes[1]
        } else

        if(index < size) {
            randomClass = classes[2]
        }

        return {
          class: `${predefinedClass} ${randomClass}`,
        };
      }

      return (
        <>
            { isAnim &&
            <div className={style.coinsContainer}>
            {arrCoins.map((coin, index) => {
                // Вычисляем угол для каждого элемента
                const angle = (360 / arrCoins.length) * index;
                return (
                    <div
                        key={index}
                        className={style.coinWrapper}
                        style={{
                            '--angle': `${angle}deg`,
                            '--delay': `${(index * index) / 100}s`
                        }}
                    >
                        <img
                            className={getAnimation(index, 13).class}
                            src="./assets/images/Coin2.png"
                            alt="coin"
                        />
                    </div>
                );
            })}
        </div>
}
            <div className={rootCls.join(' ')}>
                <h2 className={style.gameOverHeader}>Your score</h2>
                <span className={style.coinText}>
                    <img className={style.coin} src="../../../assets/images/Coin.png" alt="coin" />
                    {formatedCoins(coinsShowing)}
                </span>
                {isRoll || GameState.isRoll ? (
                    <>
                        <Cube setCoinsShowing={setCoinsShowing} setIsRoll={setIsRoll} setIsAnim={setIsAnim} setCoinsCount={setCoinsCount}/>
                        <span className={style.diceText}>try it</span>
                    </>
                ) : (
                    <>
                        <Link className={style.button} onClick={sendLink}>share with friend</Link>
                        <button
                            style={{ opacity: `${user.heart > 0 ? 1 : 0.5}` }}
                            onClick={resetGame}
                            className={style.button}
                        >
                            <span>play again <img src="../../../assets/images/Heart2.png" alt="heart" />{user.heart}</span>
                        </button>
                        <button
                            onClick={() => {
                                setCoins();
                                GameState.setRestGame(false);
                                setGameState(1);
                            }}
                            className={style.button}
                        >
                            home
                        </button>
                    </>
                )}
            </div>
        </>
    );
    
    
};

export default GameOver;
