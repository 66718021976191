import { useContext, useEffect, useState } from "react";

import Game from "../../compotents/GameCanvas/GameCanvas";
import styles from "./style.module.css";
import GameState from "../../game/state/gameManager";
import HomeCont from "../../compotents/Pages/MainLayout/MainLayout";
import GameOver from "../../compotents/Pages/GameOver/GameOver";
import DaylyHearts from "../../compotents/Pages/MainLayout/daylyHearts/daylyHearts";
import { Context } from "../..";

const Home = () => {
    const {user} = useContext(Context);
    const [zIndexCanvas, setZIndexCanvas] = useState(-2);
    const [state, setState] = useState(GameState.state);
    const [visible, setVisible] = useState(false);
    const [days, setDays] = useState(user.daysCount);
    const [time, setTime] = useState(user._timeFarming || 0);
    const [timeStart, setTimeStart] = useState(null);

    useEffect(() => {
        setTimeout(() => {
            setDays(user.daysCount);
            setTime(user._timeFarming || 0);
            console.log(user);
        }, 1000)
    }, [user.timeFarming])

    function setGameState(state) {
        setZIndexCanvas(state !== 1 ? 1000 : -2);
        setTimeStart(Date.now());
        setVisible(state === 2);
        GameState.setState(state);
    }

    useEffect(() => {
        // Устанавливаем обработчик изменения состояния
        GameState.onChange = (newState) => {
            setState(newState);
        };

        // Очищаем обработчик при размонтировании компонента
        return () => {
            GameState.onChange = null;
        }; 
        

    }, []);

    

    return (
        <>
        {
            days > 0 ?
            <DaylyHearts days={days} setDays={setDays} userSet={user.daysCount}></DaylyHearts>
            :
            <>
                {
                GameState.state === 1 && 
                <div>
                    <HomeCont setGameState={setGameState} postTime={time}></HomeCont>
                </div>
            }
            <div>
                {state == 2 && 
                    <div className={styles.gameOverBanner}>
                        <GameOver setGameState={setGameState} setState={setState} visible={visible}/>
                    </div>}
            </div>
            </>
        }
            
            <div className={styles.page} style={{ zIndex: zIndexCanvas }}>
                <Game timeStart={timeStart} setTimeStart={setTimeStart} setState={setState} state={state} setGameState={setGameState}></Game>
            </div>
        </>
    );
};

export default Home;
