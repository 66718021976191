import Pluto from '../../assets/images/planets/pluto.png';
import Neptune from '../../assets/images/planets/neptun.png'
import Uranus from "../../assets/images/planets/uran.png";
import Saturn from "../../assets/images/planets/saturn.png";
import Jupiter from "../../assets/images/planets/jupiter.png";
import Mars from "../../assets/images/planets/mars.png";
import Earth from '../../assets/images/planets/earth.png';
import Venera from '../../assets/images/planets/venera.png';
import Merkuri from '../../assets/images/planets/merkuri.png';
import Sun from '../../assets/images/planets/sun.png'


export function getLeague(coins) {
    if (coins < 30000) {
        return { league: 'Pluto', minPoints: 0, maxPoints: 30000, img: Pluto};
    } else if (coins < 100000) {
        return { league: 'Neptune', minPoints: 30000, maxPoints: 100000, img:Neptune };
    } else if (coins < 250000) {
        return { league: 'Uranus', minPoints: 100000, maxPoints: 250000, img: Uranus};
    } else if (coins < 500000) {
        return { league: 'Saturn', minPoints: 250000, maxPoints: 500000, img:Saturn };
    } else if (coins < 1000000) {
        return { league: 'Jupiter', minPoints: 500000, maxPoints: 1000000, img: Jupiter};
    } else if (coins < 2000000) {
        return { league: 'Mars', minPoints: 1000000, maxPoints: 2000000, img: Mars};
    } else if (coins < 5000000) {
        return { league: 'Earth', minPoints: 2000000, maxPoints: 5000000, img:Earth };
    } else if (coins < 10000000) {
        return { league: 'Venus', minPoints: 5000000, maxPoints: 10000000, img:Venera };
    } else if (coins < 20000000) {
        return { league: 'Mercury', minPoints: 10000000, maxPoints: 20000000, img: Merkuri};
    } else if (coins <= 50000000) {
        return { league: 'Sun', minPoints: 20000000, maxPoints: 50000000, img: Sun };
    } else {
        return { league: 'Unknown League', minPoints: 0, maxPoints: 0 };
    }
}
