import React, { useState, useEffect, useCallback, useRef, useContext } from 'react';
import { Stage, Layer, Arc } from 'react-konva';
import BackgroundImage from '../../game/entites/visual/BackgroundImage/BackgroundImage';
import PlatformImage from '../../game/entites/visual/PlatformImage/PlatformImage';
import BallImage from '../../game/entites/player/BallImage';
import BonusImage from '../../game/entites/bonuses/BonusImage';
import TimeCounter from '../../game/counters/TimeCounter';
import HeartCounter from '../../game/counters/HeartCounter';
import CoinCounter from '../../game/counters/CoinCounter';
import PlusIndicator from '../../game/gui/PlusIndicator';
import LoaderCircle from '../../game/gui/loader/loader';
import GameState from '../../game/state/gameManager';
import { Context } from '../..';
import { setCoinsUser } from '../../http/userAPI';



const BONUS_IMAGE_PATHS = [
  '../../assets/sprites/Dice1.png',
  '../../assets/sprites/Heart2.png',
  '../../assets/sprites/Coin2.png',
];


const Game = ({setState, setGameState, timeStart, setTimeStart}) => {
  const {user} = useContext(Context);

  const [bonuses, setBonuses] = useState([]);
  const [bonusImages, setBonusImages] = useState([]);
  const [hearts, setHearts] = useState(10);
  const [diceTime, setDiceTime] = useState(Math.floor(Math.random() * 2801));
  const [heartTime, setHeartTime] = useState(Math.floor(Math.random() * 2801));
  const [isEnd, setIsEnd] = useState(false);
  const [isTakeDice, setIsTakeDice] = useState(false);
  const [isRest, setIsReset] = useState(false);
  const [isControll, setIsControll] = useState(false);
  const [arrHearts, setArrHearts] = useState([]);
  const gravity = 38.83;
  const minJumpStrength = -7;
  const maxJumpStrength = -15;
  const bounceFactor = 0.4;
  const tapBoostInterval = 200;
  const timeRef = useRef(0)

  
  const ballRef = useRef(null);
  const dinaminLayerRef = useRef(null);
  const plusIndicatorsRef = useRef([]);
  const velocityRef = useRef(0);
  const coinsRef = useRef(0);
  const coinsVisualRef = useRef(null);
  const isSpawnDice = useRef(false);
  const isSpawnHeart = useRef(false);
  const timeVisualRef = useRef(null);
  const angleRef = useRef(50);
  
  const yRef = useRef(window.innerHeight / 2 + 150);


  useEffect(() => {
    const loadBonusImages = async () => {
      const loadedImages = await Promise.all(
        BONUS_IMAGE_PATHS.map(path => {
          return new Promise((resolve) => {
            const img = new Image();
            img.src = path;
            img.onload = () => resolve(img);
            img.onerror = () => resolve(null);
          });
        })
      );
      setBonusImages(loadedImages.filter(img => img !== null));
    };

    loadBonusImages();
  }, []);


  function setHeartsCount() {
  
    setDiceTime(Math.floor(Math.random() * 28));
    setHeartTime(Math.floor(Math.random() * 28));
    isSpawnDice.current = false;
    isSpawnHeart.current = false;

    const HeartsCount = 1;
  
    const newHearts = [];
  
   for (let i = 0; i < HeartsCount; i++) {
     newHearts.push(Math.floor(Math.random() * 28));
   }

   setArrHearts(newHearts.sort((a, b) => a - b));
  }

  useEffect(() => {
    setTimeStart(Date.now())
    setIsReset(GameState.isResetGame);
    
    
    if(GameState.state === 0) {
      console.log(timeRef.current, timeStart, timeVisualRef.current, timeDisplayRef.current);
      console.log(Date.now());
  
      if(isControll) {
        yRef.current = window.innerHeight / 2 + 165;
      
      }
      GameState.isRoll = false;
      setIsTakeDice(false);
      setIsControll(true);
      coinsRef.current = 0;
      bonusIntervalRef.current = setInterval(setIntervalBonuses, 2000);
      setDiceTime(Math.floor(Math.random() * 2801));
      setHeartTime(Math.floor(Math.random() * 28));
      isSpawnDice.current = false;
      isSpawnHeart.current = false;
      

      GameState.setCoins(0);
      coinsRef.current = 0;
      setHearts(user.heart);
    }
    
 
    timeRef.current = 0;
    timeVisualRef.current = null;
    angleRef.current = 0;
    
    
    plusIndicatorsRef.current = [];
    setBonuses([]);    
    setHeartsCount();



  }, [GameState.state]);

  useEffect(() => {
    renderCoins();
    
  }, [coinsRef]);

  function renderCoins() {
    if(coinsVisualRef.current == null)  {
     
    }
    else {
      
      //coinsVisualRef.current.text(coinsRef.current);
       //coinsVisualRef.current.getLayer().batchDraw();
    }
  }

  useEffect(() => {
    
    if(isEnd) {
      setIsEnd(false);
      yRef.current = window.innerHeight / 2 + 165;
      velocityRef.current+=10;
    }
    if(Math.floor(timeRef.current) === 270) {
      setIsControll(true);
    }

    if(timeRef.current >= GameState.time) {
      
      plusIndicatorsRef.current = [];
      setBonuses([]);
      setIsControll(false);
    }

       setCheckHearts();
  
  }, [timeRef.current]);


  const checkEndGame = () => {
    const downBorder = 78;
    if(yRef.current >= window.innerHeight - 80 && !isControll && timeRef.current > 3000) {
      setState(2);
      bonuses.forEach((el) => {
        el.destroy();
      })
      clearInterval(bonusIntervalRef.current);
      GameState.setState(2);
      timeRef.current = 0;
      timeDisplayRef.current = 0;
      setGameState(2);
      GameState.coins = coinsRef.current;
      user.setHeart(hearts);
    }
  };

  let tapsCount;


  const handleJump = (e) => {
    if (GameState.state !== 0 && tapsCount > 4) return;
    if(!isControll || (!GameState.isResetGame && timeRef.current < 100)) return;
    tapsCount++;
    window?.Telegram?.WebApp?.HapticFeedback.impactOccurred('medium');

    const currentTime = Date.now();
    const timeSinceLastJump = currentTime - (window.lastJumpTime || 0);
    const jumpStrength = timeSinceLastJump < tapBoostInterval
      ? Math.max(maxJumpStrength, velocityRef.current + minJumpStrength)
      : minJumpStrength;

    velocityRef.current = jumpStrength;
    window.lastJumpTime = currentTime;

   
    coinsRef.current = coinsRef.current + 1;
    if(coinsVisualRef.current) {
      coinsVisualRef.current.text(coinsRef.current);
    }
    renderCoins();

    const pointerPosition = e.target.getStage().getPointerPosition();

    const newIndicator = { x: pointerPosition.x, y: pointerPosition.y };
    const text = new window.Konva.Text({
      text: '+1',
      fontSize: 24,
      fill: 'white',
      fontFamily: 'MonBold',
      x: newIndicator.x,
      y: newIndicator.y,
      opacity: 1,
    });

    // Добавляем текст на слой
    dinaminLayerRef.current.add(text);

    // Анимация исчезновения
    text.to({
      opacity: 0,
      duration: 1,
      onFinish: () => {
        text.destroy(); // Удаляем текст после исчезновения
      },
    });
    //dinaminLayerRef
  };

  const timeDisplayRef = useRef(null);


  useEffect(() => {
    let animationFrameId;
    let lastTime = performance.now();  
  
    const update = () => {
      const currentTime = performance.now(); 
      const deltaTime = currentTime - lastTime;  
  
      lastTime = currentTime;
 
      timeRef.current = ((Date.now() - timeStart) / 10);

      if (timeDisplayRef.current) {
        timeDisplayRef.current.textContent = ((Date.now() - timeStart) / 10).toFixed(1); 
      }

      animationFrameId = requestAnimationFrame(update);
    };

    if (GameState.state === 0) {
      animationFrameId = requestAnimationFrame(update);
    }
  

    return () => cancelAnimationFrame(animationFrameId);
  
  }, [GameState.state]);
  
  
  function getY(prevY) {
    const downBorder = 78; // Нижняя граница
    const newY = prevY + velocityRef.current;

    // Проверяем, если скорость недостаточна для продолжения
    if (Math.abs(velocityRef.current) * bounceFactor < 0.5 && window.innerHeight - newY <= downBorder) {
        return window.innerHeight - downBorder; // Устанавливаем на нижнюю границу
    }

    // Проверка на нижнюю границу
    if (newY >= window.innerHeight - downBorder) {
        velocityRef.current = -Math.abs(velocityRef.current) * bounceFactor; // Инвертируем скорость
        return window.innerHeight - downBorder; // Устанавливаем на нижнюю границу
    }

    // Проверка на верхнюю границу
    if (newY <= 45) {
        velocityRef.current = Math.abs(velocityRef.current) * bounceFactor; // Инвертируем скорость
        return 45; // Устанавливаем на верхнюю границу
    }

    // Возвращаем новое значение Y
    return newY;
}

function updateBonuses() {


}

useEffect(() => {
  let animationFrameId;
  const MS_PER_UPDATE = 1000 / 60; // Обновление 60 раз в секунду
  let previous = performance.now();
  let lag = 0.0;

  const update = (time) => {
    const current = performance.now();
    lag += current - previous;
    previous = current;

    while (lag >= MS_PER_UPDATE) {
      yRef.current = getY(yRef.current);
      velocityRef.current += gravity * (MS_PER_UPDATE / 1000);
      bonuses.forEach((el) => {
        animateBonus(el, yRef.current, velocityRef.current);
      });
      if(timeVisualRef.current) {
        timeVisualRef.current.text(((GameState.time - timeRef.current) / 100).toFixed(1));
        angleRef.current.angle(360 - (3000 - timeRef.current) / 3000 * 360);
      }
 
      lag -= MS_PER_UPDATE;
    }
    if (ballRef.current) {
      ballRef.current.y(yRef.current);

      checkEndGame();
      
      
      // console.log(Math.floor(((GameState.time - timeRef.current) / 100)))
      if(Math.floor(((timeRef.current) / 100)) >= 30) {
        setIsControll(false);
      }
      dinaminLayerRef.current.getLayer().batchDraw();
      
    }

    animationFrameId = requestAnimationFrame(update);
  };

  if (GameState.state !== 1) {
    animationFrameId = requestAnimationFrame(update);
  }

  return () => cancelAnimationFrame(animationFrameId);
}, [GameState.state]);
  
  

  const bonusIntervalRef = useRef(null);

  function setIntervalBonuses(currentType = null) {
    if (document.hidden) return;
    if (bonusImages.length === 0) return;
  
    const side = Math.random() > 0.5 ? 1 : -1;
    const x = side === 1 ? window.innerWidth + 80 : -80;
    const y = 100 + Math.random() * (window.innerHeight - 200);

    let type = 2;

    if(Math.floor(timeRef.current/100) >= diceTime && !isSpawnDice.current) {
      type = 0;
      isSpawnDice.current = true;
    }
    if(Math.floor(timeRef.current/100) >= heartTime && !isSpawnHeart.current) {
      type = 1;
      isSpawnHeart.current = true;
    }
  
    const speed = 4;
    const direction = side === 1 ? -1 : 1;
  
    const bonus = new window.Konva.Image({
      x,
      y,
      side: side,
      type: type,
      width: 42,
      height: 42,
      image: type === 2 ? bonusImages[2] : type === 1 ? bonusImages[1] : bonusImages[0],
    });

    bonus.type = type;
    bonus.direction = direction;
    bonus.speed = speed;
  
    bonuses.push(bonus);
    dinaminLayerRef.current.add(bonus);


  }

  const animateBonus = (bonus, playerCenterY, velocity) => {
    if (!bonus) return; 
    bonus.x(bonus.x() + (bonus.direction * bonus.speed)); 

    const bonusCenterX = bonus.x() + bonus.width() / 2;
    const bonusCenterY = bonus.y() + bonus.height() / 2;
    const bonusRadius = 42 / 2;

    const playerCenterX = window.innerWidth / 2;
    const playerRadius = 25;

    // Определяем координаты отрезка
    const segmentStartY = playerCenterY - velocity;
    const segmentEndY = playerCenterY + velocity;

    // Проверка расстояния от бонуса до отрезка
    const distance = pointToSegmentDistance(bonusCenterX, bonusCenterY, playerCenterX, segmentStartY, playerCenterX, segmentEndY);

    if (distance < bonusRadius + playerRadius) {
        switch (bonus.type) {
            case 0:
                takeDice();
                window?.Telegram?.WebApp?.HapticFeedback.impactOccurred('heavy');
                break;
            case 1:
                setHearts(prevHearts => prevHearts + 1);
                window?.Telegram?.WebApp?.HapticFeedback.impactOccurred('medium');
                break;
            case 2:
                coinsRef.current = coinsRef.current + 100;
                if (coinsVisualRef.current) {
                    coinsVisualRef.current.text(coinsRef.current);
                }
                console.log(coinsRef.current);
                window?.Telegram?.WebApp?.HapticFeedback.impactOccurred('light');
                break;
            default:
                break;
        }

        const index = bonuses.indexOf(bonus);
        if (index !== -1) {
            bonuses.splice(index, 1);
        }
        bonus.destroy(); 
        return;
    }

    if (bonus.x() < -90 || bonus.x() > window.innerWidth + 90) { 
        bonus.destroy();
    }
};

// Функция для расчета расстояния от точки до отрезка
const pointToSegmentDistance = (px, py, x1, y1, x2, y2) => {
    const A = px - x1;
    const B = py - y1;
    const C = x2 - x1;
    const D = y2 - y1;

    const dot = A * C + B * D;
    const len_sq = C * C + D * D;
    let param = -1;
    if (len_sq !== 0) { // Ловим деление на ноль
        param = dot / len_sq;
    }

    let xx, yy;

    if (param < 0) {
        xx = x1;
        yy = y1;
    } else if (param > 1) {
        xx = x2;
        yy = y2;
    } else {
        xx = x1 + param * C;
        yy = y1 + param * D;
    }

    const dx = px - xx;
    const dy = py - yy;
    return Math.sqrt(dx * dx + dy * dy);
};


  function setCheckHearts() {
    arrHearts.forEach((el) => {
      
      if(Math.floor(timeRef.current/100) === el) {
        setArrHearts(arrHearts.filter((el, index) => index !== 0));
  
      }
    })
  }

  function takeDice() {
    if(isTakeDice) return;
    setIsTakeDice(true);
    GameState.isRoll = true;
  }


  useEffect(() => {
    let interval;

    if (GameState.state === 1 && isControll) {
      // Удаление PlusIndicator после анимации
      interval = setInterval(() => {
   
        plusIndicatorsRef.current = plusIndicatorsRef.current.slice(1);
      }, 750);

      return () => clearInterval(interval);
    }
  }, []);

  return (
    <Stage
      width={window.innerWidth}
      height={window.innerHeight}
      onTouchStart={handleJump}
      onMouseDown={handleJump}
    >

      <Layer>
      <BackgroundImage />
        {
          GameState.state !== 1 && GameState.state !==5 && 
          <>
          <PlatformImage />
          </>
         
        }
        { (GameState.state !== 1 || GameState.state !== 5) &&
           null
        }
        {GameState.state === 0 && GameState.state !==5 && isControll &&  (
          <>
            
            {/* {bonuses.map((bonus, index) => (
              <BonusImage type={bonus.type} key={index} isDestroy={bonus.isDestroy} image={bonusImages[bonus.type]} x={bonus.x} y={bonus.y} />
            ))} */}
            
          </>
        )}
      </Layer>
      <Layer ref={dinaminLayerRef}>
        {
          GameState.state !== 1 && GameState.state !==5 &&  
          <>
            {
              isControll &&
              <>
                <TimeCounter ref={timeVisualRef} isTakeDice={isTakeDice} time={((GameState.time - timeRef.current) / 100).toFixed(1)} />
                <HeartCounter hearts={hearts} />
                <CoinCounter coins={coinsRef.current} ref={coinsVisualRef}/>
                {/* <LoaderCircle
                  x={window.innerWidth / 2}
                  y={window.innerHeight / 2 * 1.015 + 0}
                  radius={window.innerWidth / 3.1}
                  startTime={timeStart}
                  borderColor="#ffffff00"
                  fillColor="#5297ff35"
                  strokeWidth={window.innerWidth / 24}
                />  */}
              

              {/* Заполненный круг */}
              <Arc
                x={window.innerWidth / 2 -1}
                y={window.innerHeight / 2  + 6}
                innerRadius={window.innerWidth / 3.1 - window.innerWidth / 24 / 2 - 1}
                outerRadius={window.innerWidth / 3.1}
                ref={angleRef}
               
                
                fill="#5297ff60"
                strokeWidth={window.innerWidth / 24}
                rotation={-90}
                lineJoin="round"
                shadowColor="#5297ff"
                shadowBlur={5}
                shadowOffsetX={0}
                shadowOffsetY={0}
                scaleY={0.978}
                scaleX={0.97}
              />
              </>
            }
            <BallImage ref={ballRef} isEnd={isEnd} setIsEnd={setIsEnd} y={yRef.current} isAnimation={!isRest} />
            
          </>
        }
        {/* {
          GameState.state === 2 &&  <BallImage y={window.innerHeight - 80} isAnimation={false} />
        } */}
       
      </Layer>
      
    </Stage>
  );
};

export default Game;